
import { inject } from "vue";
import { Database } from "vuebase";
import { Product } from "../main";
import pluralize from "pluralize";

export default {
    name: "EditProducts",
    setup() {
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();

        return {
            products,
            pluralize,
        };
    },
};

<template>
    <c-panel>
        <c-grid class="widths-50-sm widths-33-md widths-25-lg widths-25-xl">
            <template v-for="product in products" :key="product.id">
                <c-grid-item v-if="product.data?.images" class="flex f-align-stretch">
                    <c-card @click="$router.push('/admin/product/' + product.id)">
                        <template #image v-if="product.data.images[0]">
                            <c-image :image="product.data.images[0]" />
                        </template>
                        <template #title>{{ pluralize(product.data.name) }}</template>
                        <template #body>{{ product.data.caption }}</template>
                    </c-card>
                </c-grid-item>
            </template>
        </c-grid>
        <c-button class="mt-5" @click="$router.push('/admin/product')">
            <template #icon>
                <i class="fas fa-plus"></i>
            </template>
            New Product
        </c-button>
    </c-panel>
</template>

<script lang="ts">
import { inject } from "vue";
import { Database } from "vuebase";
import { Product } from "../main";
import pluralize from "pluralize";

export default {
    name: "EditProducts",
    setup() {
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();

        return {
            products,
            pluralize,
        };
    },
};
</script>
